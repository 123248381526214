import classNames from "classnames";
import styles from "./styles/GalleryBlock.module.scss";
import SocialMarkdown from "../../../utilities/SocialMarkdown";
import {getPlatform} from "../../../../utils/StringUtilities";
import {EditButton} from "../EditButton";
import {getAltText, IReactiveMediaContentItem} from "../../../../stores/SchoolFeedStore";
import {handleContentItemClick} from "../../SchoolBlocks/ContentItemBlock/ContentItemBlock";
import {SocialItemModalNavigationProps} from "../../../modals/SocialItemModal";
import React, {useContext} from "react";
import {StoreContext} from "../../../../stores/StoreLoader";
import {observer} from "mobx-react";

const ImageGalleryItem = observer((props: {
    item: IReactiveMediaContentItem,
    handleDelete: (contentItem: ContentItem) => void,
} & SocialItemModalNavigationProps) => {

    const {userStore} = useContext(StoreContext);

    const imageWrapperClassName = classNames({
        [styles.imageWrapper]: true,
        [styles.imageWrapperHidden]: !props.item.published,
    });

    const imgSource = props.item.image || "";

    function handleClick(e) {
        if (e.key && e.key !== "Enter") return;
        e.preventDefault();
        handleContentItemClick(props.item as IReactiveMediaContentItem, {
            getItemAtIndex: props.getItemAtIndex,
            totalItemCount: props.totalItemCount,
            initialItemIndex: props.initialItemIndex,
        }, props.handleDelete)
    }

    return (
        <div className={styles.galleryItem} tabIndex={0} onClick={handleClick} onKeyDown={handleClick}>
            <div className={imageWrapperClassName}>
                <img className={styles.item}
                     key={props.item.id}
                     src={imgSource}
                     alt={getAltText(props.item)} />
                {(props.item.description || props.item.title) && <div className={styles.photoDescription}>
                    <div>{props.item.title}</div>
                    {props.item.description && <SocialMarkdown platform={getPlatform(props.item.type)} text={props.item.description} />}
                </div>}
            </div>
            {userStore.isEditor &&
                <EditButton
                    handleDelete={props.handleDelete}
                    contentItem={props.item}
                    className={styles.editButton}
                />}
        </div>
    )
});

export default ImageGalleryItem;
